.button {
  cursor: pointer;
  border: none;
  background: transparent;
  font-family: sans-serif;
  color: rgb(255, 255, 255);
  padding-left: 5px;
}
.MuiBottomNavigationAction {
  flex: 1;
  color: white !important;
  padding: 6px 0px 8px !important;
  max-width: 168px;
  min-width: 80px;
  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}
.MuiBottomNavigationActionNoSelect {
  color: white !important;
  padding: 6px 0px 8px !important;
  font-size: 0.75rem !important;
}
.MuiBottomNavigationAction.Mui-selected {
  color: black !important;
  background-color: white;
  text-decoration: none !important;
  padding: 6px 0px 8px !important;
}
.MuiBottomNavigationAction-label.Mui-selected {
  font-size: 0.75rem !important;
}
.MuiBottomNavigationActionNoSelect.Mui-selected {
  color: white !important;
  text-decoration: none !important;
  padding: 6px 0px 8px !important;
}
.icon[opening='1'] {
  animation: open 0.2s 1;
}
.icon[closing='1'] {
  animation: close 0.2s 1;
}
@keyframes open {
  from {
    bottom: 65px;
  }
  to {
    bottom: 10px;
  }
}
@keyframes close {
  from {
    bottom: 10px;
  }
  to {
    bottom: 65px;
  }
}
